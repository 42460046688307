<template>
  <div class="container">
    <table class="table">
      <thead>
        <tr>
          <th colspan="4">
            <h2>
              Interventions
              <button @click="add()"><i class="fa fa-plus-square"></i></button>
            </h2>
          </th>
        </tr>
      </thead>

      <thead>
        <tr>
          <th scope="col"><i class="fa fa-calendar"></i> Date</th>
          <th scope="col"><i class="fa fa-comment"></i> inter</th>
          <th scope="col"><i class="fa fa-lightbulb-o"></i> Feu</th>
          <th scope="col"><i class="fa fa-paint-brush"></i> Couleur</th>
          <th scope="col"><i class="fa fa-edit"></i> Modifier</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="inter.id" v-for="inter in inters">
          <td>{{ inter.dt | sdt }}</td>
          <td>{{ inter.Sujet }}</td>
          <td>{{ inter.nomfeu }}</td>
          <td>
            <span v-if="inter.strcode != null"
              ><img :src="svg(inter.strcode)"
            /></span>
          </td>
          <td>
            <a href="#" @click="edit(inter.id)"><i class="fa fa-edit"></i></a>
          </td>
        </tr>
      </tbody>

      <thead>
        <tr>
          <th colspan="4"><h2>Interventions</h2></th>
        </tr>
      </thead>

      <thead>
        <tr>
          <th scope="col"><i class="fa fa-calendar"></i> Date</th>
          <th scope="col"><i class="fa fa-comment"></i> Intervention</th>
          <th scope="col"><i class="fa fa-lightbulb-o"></i> Feu</th>
          <th scope="col"><i class="fa fa-paint-brush"></i> Couleur</th>
          <th scope="col">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="intervention.id" v-for="intervention in interventions">
          <td>{{ intervention.dt | sdt }}</td>
          <td>{{ intervention.Sujet }}</td>
          <td>{{ intervention.nomfeu }}</td>
          <td>
            <span v-if="intervention.strcode != null"
              ><img :src="svg(intervention.strcode)"
            /></span>
          </td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import store from "../store";
import { getPrefix, selectApi } from "../lib/api.js";
import { getPath } from "../lib/svg.js";

export default {
  name: "inter",
  store: store,
  data() {
    return {
      counta: 0,
      inters: [],
      counti: 0,
      interventions: [],
    };
  },
  filters: {
    sdt: function (dt) {
      if (!dt) return "";
      return (
        dt.substring(8, 10) +
        "/" +
        dt.substring(5, 7) +
        "/" +
        dt.substring(0, 4)
      );
    },
  },
  methods: {
    add() {
      this.$router.push({ path: "/addinter/0/0/0" });
    },
    edit(id) {
      this.$router.push({ path: "/addinter/" + id + "/0/0" });
    },
    svg(code) {
      let path = getPath(code, 0, false);
      return path;
    },
    refresh() {
      let id = store.state.piste;
      let sql =
        "SELECT a.id,a.dt,a.Sujet,a.remarque,f.id as idfeu,f.nom as nomfeu,c.strcode FROM interpiste as a LEFT JOIN maintenance as m ON m.id=a.idmaintenance LEFT JOIN feu as f on f.id=a.idfeu LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur WHERE a.client=1 and m.idpiste=" +
        id +
        " ORDER BY a.dt ASC";
      selectApi(this, sql).then(
        (response) => {
          this.counta = response.data.count;
          this.inters = response.data.result;
        },
        (response) => {
        }
      );

      sql =
        "SELECT a.id,a.dt,a.Sujet,a.remarque,f.id as idfeu,f.nom as nomfeu,c.strcode FROM interpiste as a LEFT JOIN maintenance as m ON m.id=a.idmaintenance LEFT JOIN feu as f on f.id=a.idfeu LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur WHERE a.client=1 and m.idpiste=" +
        id +
        " ORDER BY a.dt ASC";
      selectApi(this, sql).then(
        (response) => {
          this.counti = response.data.count;
          this.interventions = response.data.result;
        },
        (response) => {
        }
      );
    },
  },
  mounted() {
    if (store.state.typeUser == 0) {
      this.$router.push({ name: "login" });
    }
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/design.scss";

ul {
  list-style-type: none;
}
</style>